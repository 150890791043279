import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ItemComponent } from './item/item.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'search', component: SearchResultsComponent},
  { path: 'item/:route', component: ItemComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
