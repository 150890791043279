import { Component, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'search-paginator',
  templateUrl: './search-paginator.component.html',
  styleUrls: ['./search-paginator.component.css']
})
export class SearchPaginatorComponent {

  limit: number = 15;
  page: number = 1;
  pages: number = 1;

  queryParamsSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, searchService: SearchService) {

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      queryParams => {

        if (this.page != queryParams['page']) this.page = Number(queryParams['page']) || 1;

        if (this.limit != queryParams['limit']) this.limit = Number(queryParams['limit']) || 15;

        searchService.getCount().subscribe(count => {

          this.pages = Math.ceil(count / this.limit);

        });


      }
    );
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

}
