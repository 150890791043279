import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search.service'

import $ from 'jquery';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css'],
  providers: [SearchService]
})
export class SearchResultsComponent {

  public step;

  public mobileFilters: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, searchService: SearchService) {

    searchService.updateFilters();

    this.activatedRoute.queryParams.subscribe(params => {

      let filters = Object.assign({}, ...Object.keys(params).filter((key) => !['category', 'order', 'sort', 'page', 'limit', 'txt', 'min', 'max'].includes(key)).map(key => ({ [key]: params[key] })));

      //search(category?, filters?, order?, sort?, page?, limit?, txt?, min?, max?, count?, pricer?, args?)
      searchService.search(
        params['category'] || null,
        params['order'] || null,
        params['sort'] || null,
        params['page'] || null,
        params['limit'] || null,
        params['txt'] || null,
        params['min'] || null,
        params['max'] || null,
        filters || null
      );

      if (params['category']) searchService.updateFilters(params['category']);

    });

  }

}
