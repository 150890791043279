<div id="top-message">
    <span>
        PRECIOS APLICADOS PARA COMPRAS EN EFECTIVO, GIROS TIGO O TRANSFERENCIA BANCARIA.
    </span>
</div>

<div id="header-wrapper">

    <div id="header-content">

        <section id="nav-mobile-toggle-wrapper" class="header-section">
            <button id="nav-mobile-toggle-button" (click)="toggleNavMobile()">
                <fa-icon [icon]="faBars" [size]="'2x'"></fa-icon>
            </button>
        </section>

        <section id="logo-wrapper" class="header-section">
            <a routerLink="/">
                <img class="logo-img" src="/assets/img/mini-logo.png">
            </a>
        </section>

        <section id="search-wrapper" class="header-section">
            <form (submit)="search(txt)">
                <input type="text" class="search-input" placeholder="Qué buscas?" #txt>
                <button class="search-input-btn" (click)="search(txt)">
                    <i class="fas fa-search"></i>
                </button>
            </form>
        </section>

        <section id="user-wrapper" class="header-section">
            <a class="icon-link" routerLink="user">
                <fa-icon [icon]="faUser" [size]="'2x'"></fa-icon>
                <span>Hola, {{ user.name }}</span>
            </a>
        </section>

        <section class="mobile-search-toggle header-section">
            <button (click)="toggleSearchBar()">
                <i class="fas fa-search"></i>
            </button>
        </section>

        <section id="cart-wrapper" class="header-section">
            <a class="icon-link" routerLink="cart">
                <fa-icon [icon]="faShoppingCart" [size]="'2x'"></fa-icon>
                <span> {{ cart.qty }}</span>
            </a>
        </section>

    </div>

</div>

<div class="mobile-search">
    <form (submit)="search(mtxt)">
        <input type="text" class="search-input" placeholder="Qué buscas?" #mtxt>
        <button class="search-input-btn" (click)="search(mtxt)">
            <i class="fas fa-search"></i>
        </button>
    </form>
</div>

<hr>

<navbar></navbar>

<nav-mobile (closeEvent)="toggleNavMobile()"></nav-mobile>