<div class="item-wrapper"  #wrapper [class.selected] ="selected" [class.not-available]="item.availability == 0">
    <a class="item-link" [routerLink]="['/item', item.code]">
        <div class="item-image" #image>
            <img [src]="item.image ? 'https://erp.pcgaragepy.com/' + item.image : 'https://erp.pcgaragepy.com/files/broken.png'">
            <ul class="item-labels">
                <li class="item-label item-label-used" *ngIf="item.used">
                    USADO
                </li>
            </ul>
            <span *ngIf="item.image" class="item-image-shadow" [style.width.px]="image.offsetWidth / 2" [style.height.px]="image.offsetWidth / 12"></span>
            <sale-tag *ngIf="item.offer && item.availability == 1" [price]="item.price" [salePrice]="item.offer"></sale-tag>
        </div>
        <div class="item-info">
            <section class="item-description">
                <p class="item-name" [innerText]="getNameDiff()"></p>
                <p class="item-category">{{ item.category }}</p>
            </section>
            <div class="item-prices" *ngIf="item.availability == 1">
                <div class="item-price-wrapper">
                    <p class="item-price item-price-regular" [class.item-price-strikethrough]="item.offer">
                        <span class="item-price-symbol">₲</span>
                        {{ item.price | currency:'PYG':'':'1.0-0'}}
                    </p>
                </div>
                <div class="item-price-wrapper" *ngIf="item.offer">
                    <p class="item-price item-price-offer">
                        <span class="item-price-symbol">₲</span>
                        {{ item.offer | currency:'PYG':'':'1.0-0'}}
                    </p>
                </div>

            </div>
            <div class="item-not-available" *ngIf="item.availability == 0">NO DISPONIBLE</div>
        </div>
        <sale-tag *ngIf="item.offer && item.availability == 1" [price]="item.price" [salePrice]="item.offer"></sale-tag>
    </a>
</div>