import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'],
  providers: [SearchService]
})
export class ItemComponent {

  public item: any = {};
  public images: any[];

  public index: number = 0;

  public carrouselHeight: number = 0;

  constructor(public router: Router, activatedRoute: ActivatedRoute, searchService: SearchService) {

    searchService.getItem(activatedRoute.snapshot.params['route']).subscribe(r => {

      let item = r['message']['item'][0];

      item.manufacturerLink = item.manufacturerLink ? (item.manufacturerLink.includes('http://') || item.manufacturerLink.includes('https://') ? item.manufacturerLink : `https://${item.manufacturerLink}`): item.manufacturerLink;

      this.item = item;

      this.images = r['message']['images'];

      this.images.forEach(i => {

        let image = new Image();

        image.src = "https://erp.pcgaragepy.com" + i.link;

        image.onload = () => { if (image.height > this.carrouselHeight) this.carrouselHeight = image.height };

      });

    });

  }

}
