import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  private URL = 'https://erp.pcgaragepy.com/api/';

  private METHOD_COUNTDOWN_LIST = "method/erpnext.shop.homepage.getCountdownCarouselItems"
  private METHOD_SALE_LIST = "method/erpnext.shop.homepage.getSaleCarouselItems"
  private METHOD_FEATURED_LIST = "method/erpnext.shop.homepage.getFeaturedCarouselItems"
  private METHOD_CATEGORIES_GRID = "method/erpnext.shop.homepage.getCategoriesGrid"

  constructor(private http: HttpClient) { }

  getCountdownCarouselItems() {
    return this.http.get(this.URL + this.METHOD_COUNTDOWN_LIST)
  }

  getSaleCarouselItems() {
    return this.http.get(this.URL + this.METHOD_SALE_LIST)
  }

  getFeaturedCarouselItems() {
    return this.http.get(this.URL + this.METHOD_FEATURED_LIST)
  }

  getCategoriesGrid() {
    return this.http.get(this.URL + this.METHOD_CATEGORIES_GRID);
  }

}
