<div id="footer-wrapper">
    <div class="footer-section" id="main-info">
        <img src="/assets/img/logo.png" />
        <div>
            <a href="#">Inicio</a> |
            <a href="#">Mapa del Sitio</a>
        </div>
        <div id="social-info">
            <a class="social-info" href="https://facebook.com/PcGarage.Py">
                <i class="fa fa-facebook"></i>
            </a>
            <a class="social-info" href="https://instagram.com/pcgarage.py">
                <i class="fa fa-instagram"></i>
            </a>
            <a class="social-info" href="https://wa.me/595984964588">
                <i class="fa fa-whatsapp"></i>
            </a>
        </div>
        <p id="copyright">© 2019 PC Garage Paraguay</p>
    </div>
    <hr>
    <div class="footer-section" id="comercial-info">
        <div>
            <a class="comercial-info" href="https://goo.gl/maps/KGLcP3s5BKVoe6SF9">
                <fa-icon [icon]="faMapMarkedAlt" [size]="'lg'"></fa-icon>
                <div id="address">
                    <p id="city">Fernando de la Mora</p>
                    <p id="direction">Fortín Camacho 244 c/ Algodonal</p>
                </div>
            </a>
            <a class="comercial-info" href="https://wa.me/595984964588">
                <fa-icon [icon]="faPhoneAlt" [size]="'lg'"></fa-icon>
                <p>+595 (984) 964 588</p>
            </a>
            <a class="comercial-info" href="mailto:replies@pcgaragepy.com">
                <fa-icon [icon]="faEnvelope" [size]="'lg'"></fa-icon>
                <p>replies@pcgaragepy.com</p>
            </a>
        </div>
    </div>
    <hr>
    <div class="footer-section" id="category-info">
        <a class="category-info" href="https://pcgarage.com.py/search?category=Mouse">
            <fa-icon [icon]="faMouse"></fa-icon>
            <p>Mouse</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Teclado">
            <fa-icon [icon]="faKeyboard"></fa-icon>
            <p>Teclado</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Headset (Auricular)">
            <fa-icon [icon]="faHeadphones"></fa-icon>
            <p>Headset</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Monitor">
            <fa-icon [icon]="faDesktop"></fa-icon>
            <p>Monitor</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Disco Duro (HDD)">
            <fa-icon [icon]="faHdd"></fa-icon>
            <p>Disco Duro</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=SSD">
            <fa-icon [icon]="faSdCard"></fa-icon>
            <p>SSD</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Fuente (PSU)">
            <fa-icon [icon]="faCarBattery"></fa-icon>
            <p>Fuente</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Gabinete">
            <fa-icon [icon]="faMobile"></fa-icon>
            <p>Gabinete</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Cooler FAN (Ventilador)">
            <fa-icon [icon]="faFan"></fa-icon>
            <p>Cooler</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Procesador (CPU)">
            <i><svg xmlns="http://w3.org/2000/svg" xmlns:xlink="http://w3.org/1999/xlink" version="1.1"
                        id="Capa_1" x="0px" y="0px" viewBox="0 0 611.997 611.997"
                        style="enable-background:new 0 0 611.997 611.997;" xml:space="preserve">
                        <g>
                            <g>
                                <path
                                    d="M404.199,143.793H207.798c-30.857,0-55.874,25.018-55.874,55.885v212.643c0,30.865,25.018,55.886,55.874,55.886h196.403    c30.86,0,55.874-25.019,55.874-55.886V199.678C460.075,168.811,435.059,143.793,404.199,143.793z" />
                                <path
                                    d="M578.497,329.491c12.974,0,23.491-10.517,23.491-23.491c0-12.974-10.517-23.491-23.491-23.491h-53.624v-53.075h53.624    c12.974,0,23.491-10.517,23.491-23.491s-10.517-23.491-23.491-23.491h-54.1c-4.596-51.934-44.108-93.966-94.856-102.416V23.491    C429.541,10.517,419.024,0,406.05,0c-12.974,0-23.491,10.517-23.491,23.491V78.46h-53.072V23.491    C329.487,10.517,318.971,0,305.997,0s-23.492,10.517-23.492,23.491V78.46h-53.07V23.491C229.434,10.517,218.917,0,205.943,0    c-12.974,0-23.491,10.517-23.491,23.491v56.544c-50.749,8.45-90.262,50.482-94.856,102.416H33.498    c-12.974,0-23.491,10.517-23.491,23.491c0,12.974,10.517,23.491,23.491,23.491h53.624v53.075H33.498    c-12.974,0-23.491,10.517-23.491,23.491c0,12.974,10.517,23.491,23.491,23.491h53.624v53.074H33.498    c-12.974,0-23.491,10.517-23.491,23.491c0,12.974,10.517,23.491,23.491,23.491h54.1c4.594,51.932,44.108,93.965,94.856,102.413    v56.549c0,12.974,10.517,23.491,23.491,23.491s23.491-10.517,23.491-23.491v-54.974h53.07v54.974    c0,12.974,10.518,23.491,23.492,23.491s23.491-10.517,23.491-23.491v-54.974h53.072v54.974c0,12.974,10.517,23.491,23.491,23.491    c12.974,0,23.491-10.517,23.491-23.491v-56.545c50.749-8.448,90.261-50.48,94.856-102.413h54.1    c12.974,0,23.491-10.517,23.491-23.491s-10.517-23.491-23.491-23.491h-53.624v-53.074h53.622V329.491z M487.352,419.413    c0,42.238-34.364,76.602-76.607,76.602H201.249c-42.241,0-76.605-34.362-76.605-76.602v-226.83    c0-42.24,34.364-76.604,76.605-76.604h209.496c42.241,0,76.607,34.364,76.607,76.604L487.352,419.413L487.352,419.413z" />
                            </g>
                        </g>
                    </svg></i>
            <p>Procesador</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Memoria RAM">
            <fa-icon [icon]="faMemory"></fa-icon>
            <p>RAM</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Placa Madre (MB)">
            <fa-icon [icon]="faLayerGroup"></fa-icon>
            <p>Placa Madre</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Tarjeta Gráfica (GPU)">
            <i><svg role="img" viewBox="0 -44 512 512">
                        <path
                            d="m492 49.730469h-412.667969v-29.730469c0-11.046875-8.953125-20-20-20h-39.332031c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h19.332031v363.648438c0 11.046874 8.953125 20 20 20s20-8.953126 20-20v-39.414063h412.667969c11.046875 0 20-8.953125 20-20v-274.503906c0-11.046875-8.953125-20-20-20zm-20 274.503906h-392.667969v-234.503906h392.667969zm-235.332031 79.414063c0 11.046874-8.953125 20-20 20h-78.667969c-11.046875 0-20-8.953126-20-20 0-11.042969 8.953125-20 20-20h78.667969c11.042969 0 20 8.957031 20 20zm236 0c0 11.046874-8.953125 20-20 20h-157.335938c-11.046875 0-20-8.953126-20-20 0-11.042969 8.953125-20 20-20h157.332031c11.046876 0 20.003907 8.957031 20.003907 20zm-98.667969-117.664063c43.5625 0 79-35.441406 79-79 0-43.5625-35.4375-79-79-79s-79 35.4375-79 79c0 43.558594 35.4375 79 79 79zm0-118c21.503906 0 39 17.492187 39 39 0 21.503906-17.496094 39-39 39s-39-17.496094-39-39c0-21.507813 17.496094-39 39-39zm-196.667969 118c43.5625 0 79-35.441406 79-79 0-43.5625-35.4375-79-79-79-43.558593 0-79 35.4375-79 79 0 43.558594 35.441407 79 79 79zm0-118c21.507813 0 39 17.492187 39 39 0 21.503906-17.492187 39-39 39-21.503906 0-39-17.496094-39-39 0-21.507813 17.496094-39 39-39zm0 0" />
                        </svg></i>
            <p>Tarjeta Gráfica</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Mouse Pad">
            <fa-icon [icon]="faSquare"></fa-icon>
            <p>Mousepad</p>
        </a>
        <a class="category-info" href="https://pcgarage.com.py/search?category=Silla">
            <i><svg xmlns="http://w3.org/2000/svg" xmlns:xlink="http://w3.org/1999/xlink" version="1.1"
                        id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                        xml:space="preserve">
                        <g>
                            <g>
                                <path
                                    d="M307.252,455.56h-36.188v-25.596h17.264c5.544,0,10.04-4.495,10.04-10.039v-18.14h-84.724v18.14h-0.004    c0,5.544,4.495,10.039,10.04,10.039h17.264v25.596h-36.188c-18.382,0-33.338,14.956-33.338,33.338v8.043    c0,8.316,6.742,15.059,15.059,15.059c8.317,0,15.059-6.742,15.059-15.059v-8.043c0-1.776,1.445-3.221,3.221-3.221h36.188v11.264    c0,8.316,6.742,15.059,15.059,15.059c8.316,0,15.059-6.742,15.059-15.059v-11.264h36.188c1.775,0,3.221,1.445,3.221,3.221v8.043    c0,8.316,6.742,15.059,15.059,15.059c8.317,0,15.059-6.742,15.059-15.059v-8.043C340.59,470.516,325.634,455.56,307.252,455.56z" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    d="M416.211,191.578h-24.234c-10.009,0-18.124,8.114-18.124,18.124v14.158c0,6.318,5.122,11.44,11.44,11.44v55.295h-16.181    c-3.701-4.04-7.817-7.694-12.287-10.884V63.06c0-34.827-28.232-63.06-63.06-63.06h-75.527c-34.827,0-63.06,28.233-63.06,63.06    v216.652c-4.47,3.19-8.586,6.844-12.287,10.884h-16.182V235.3c6.318,0,11.44-5.122,11.44-11.44v-14.158    c0-10.009-8.114-18.123-18.123-18.123H95.793c-10.009,0-18.124,8.114-18.124,18.124v14.158c0,6.318,5.122,11.44,11.44,11.44h7.482    v70.354c0,8.317,6.742,15.059,15.059,15.059h14.086c-2.132,7.084-3.287,14.588-3.287,22.359v13.794c0,8.284,6.716,15,15,15    h237.102c8.284,0,15-6.716,15-15v-13.794c0-7.771-1.154-15.275-3.287-22.359h14.086c8.317,0,15.059-6.742,15.059-15.059V235.3    h7.482c6.318,0,11.44-5.122,11.44-11.44v-14.158C434.335,199.692,426.22,191.578,416.211,191.578z" />
                            </g>
                        </g>
                    </svg></i>
            <p>Silla</p>
        </a>
    </div>
</div>