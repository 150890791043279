<div class="wrapper">

    <div class="container">

        <div class="selected-filters">

            <p class="selected-filters-label">FILTROS SELECCIONADOS</p>

            <mat-divider></mat-divider>

            <mat-chip-list>

                <mat-chip [selectable]="true" [removable]="true"
                    (removed)="removeQueryParam(selectedFilter.filter, selectedFilter.option)"
                    *ngFor="let selectedFilter of selectedFilters">
                    {{ ['min','max'].includes(selectedFilter.filter) ? selectedFilter.filter == 'min' ? 'Más de ' + (selectedFilter.option | currency:'PYG':'':'1.0-0') : 'Hasta ' + (selectedFilter.option | currency:'PYG':'':'1.0-0') : selectedFilter.option }}
                    <mat-icon matChipRemove><i class="far fa-times-circle"></i></mat-icon>

                </mat-chip>

            </mat-chip-list>

            <span class="mobile-close" (click)="mobileClose()">
                <i class="fa fa-times"></i>
            </span>

        </div>

        <div class="content">

            <div class="filters">

                <p>FILTROS</p>

                <mat-divider></mat-divider>

                <div class="filter" *ngIf="categoryFilter.categories">

                    <mat-expansion-panel [expanded]="true">

                        <mat-expansion-panel-header expandedHeight="48px" style="padding-left: 16px">

                            <p class="filter-label" style="font-weight: bold; margin-left: 0; color: #fff">CATEGORIAS</p>

                        </mat-expansion-panel-header>

                        <div class="filter-option-search-wrapper"
                            [style.display]="'block'">

                            <input #csearch class="filter-option-search" type="text"
                                (keyup)="categoryFilter.search = csearch.value">

                            <i class="fa fa-search"></i>

                        </div>

                        <div class="filter-options">

                                <ul>
                                    <li *ngFor="let category of categoryFilter.categories" class="filter-option filter-category"
                                        [style.display]="!categoryFilter.search || category.name.toLowerCase().includes(categoryFilter.search.toLowerCase()) ? 'flex' : 'none'">
                                        <a routerLink="/search" [queryParams]="{category: category.name}" queryParamsHandling="merge">{{ category.name }}</a>
                                    </li>
                                </ul>

                        </div>

                    </mat-expansion-panel>

                    <mat-divider></mat-divider>


                </div>

                <p style="font-size:14px; font-family:'Montserrat'">PRECIO</p>

                <div class="pricer">

                    <mat-form-field appearance="fill">
                        <mat-label>min</mat-label>
                        <input matInput type="text" class="example-right-align" [textMask]="{mask: mask}" #min>
                        <span matPrefix>₲&nbsp;</span>
                        <span matSuffix>.000</span>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>max</mat-label>
                        <input matInput type="text" class="example-right-align" [textMask]="{mask: mask}" #max>
                        <span matPrefix>₲&nbsp;</span>
                        <span matSuffix>.000</span>
                    </mat-form-field>

                    <button mat-raised-button (click)="submitPricer(min, max)">
                        APLICAR
                    </button>

                </div>

                <mat-divider></mat-divider>

                <mat-accordion multi="true">

                    <div class="filter" *ngFor="let filter of filters">

                        <mat-expansion-panel [expanded]="filter.expanded">

                            <mat-expansion-panel-header expandedHeight="48px">

                                <p class="filter-label">{{ filter.name.toUpperCase() }}</p>

                            </mat-expansion-panel-header>

                            <div class="filter-option-search-wrapper"
                                [style.display]="filter.options.length > 4 ? 'block' : 'none'">

                                <input #search class="filter-option-search" type="text"
                                    (keyup)="filter.search = search.value">

                                <i class="fa fa-search"></i>

                            </div>

                            <div class="filter-options">

                                <ng-template [ngIf]="filter.type.toLowerCase() == 'multiple'" [ngIfElse]="radio">
                                    <ul>
                                        <li *ngFor="let option of filter.options" class="filter-option"
                                            [style.display]="!filter.search || option.name.toLowerCase().includes(filter.search.toLowerCase()) ? 'flex' : 'none'">
                                            <input type="checkbox" [id]="'checkbox-' + filter.name + '-' + option.name"
                                                name="checkbox" [(ngModel)]="option.selected"
                                                (change)="updateQueryParams($event, filter, option)" />
                                            <label [for]="'checkbox-' + filter.name + '-' + option.name"></label>
                                            <p class="filter-option-label">{{ option.name }}</p>
                                        </li>
                                    </ul>
                                </ng-template>

                                <ng-template #radio>
                                    <mat-radio-group>
                                        <mat-radio-button *ngFor="let option of filter.options" class="filter-option"
                                            [value]="option.name">
                                            <p class="filter-option-label">{{ option.name }}</p>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </ng-template>

                            </div>

                        </mat-expansion-panel>

                        <mat-divider></mat-divider>

                    </div>

                </mat-accordion>

            </div>

        </div>

    </div>

</div>