<div *ngIf="validUpto > now && show" class="container" [style.minHeight.px]="container.offsetWidth / 2" [style]="'--background: url(' + background + ')'" #container>
    <h1>{{title}}</h1>
    <span class="date show">{{subtitle}}</span>

    <flipdown [date]="validUpto"></flipdown>

    <div style="width: 100%; padding: 2rem 0;">

        <glide-items [items]="items"></glide-items>

    </div>
</div>