import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const api = "https://erp.pcgaragepy.com/api/";
const navbar = "method/erpnext.shop.main.getNavbar"

@Injectable({
  providedIn: 'root'
})
export class AppService {

  navbar: BehaviorSubject<any>;

  constructor(private http: HttpClient) { this.init() }

  init() {

  }

  getNavbar() {
      return this.http.get(`${api}${navbar}`)
  }


}
