<div class="wrapper" *ngIf="pages > 0">

    <a routerLink="/search" [queryParams]="{page: 1}" queryParamsHandling="merge">
        <button [disabled]="page == 1"><i class="fas fa-angle-double-left"></i>
        </button>
    </a>

    <a routerLink="/search" [queryParams]="{page: page - 1}" queryParamsHandling="merge">
        <button [disabled]="page == 1">
            <i class="fas fa-angle-left"></i>
        </button>
    </a>

    <a routerLink="/search" [queryParams]="{page: page == 1 ? 1 : page - 1}" queryParamsHandling="merge">
        <button [class.current-page]="page == 1" [disabled]="page == 1"
            [innerText]="(page==1) ? page : (page==pages && pages > 2) ? page -2 : (pages == 2) ? 1 : page - 1"></button>
    </a>

    <a routerLink="/search" [queryParams]="{page: (page==1) ? page + 1 :  (page==pages) ? page - 1  : page}"
        queryParamsHandling="merge">
        <button [class.current-page]="page != 1 && ((page != pages && pages > 2) || (page == 2 && pages == 2))"
            [disabled]="page != 1 && page != pages || pages == 2 && page == 2" *ngIf="pages > 1"
            [innerText]="(page==1) ? page + 1 : (page==pages && pages > 2) ? page -1 : page"></button>
    </a>

    <a routerLink="/search" [queryParams]="{page: (page==1) ? page + 2 : page + 1}" queryParamsHandling="merge">
        <button [class.current-page]="page == pages" [disabled]="page == pages" *ngIf="pages > 2"
            [innerText]="(page==1) ? page + 2 : (page==pages) ? page : page + 1"></button>
    </a>

    <a routerLink="/search" [queryParams]="{page: page + 1}" queryParamsHandling="merge">
        <button [disabled]="page == pages">
            <i class="fas fa-angle-right"></i>
        </button>
    </a>

    <a routerLink="/search" [queryParams]="{page: pages}" queryParamsHandling="merge">
        <button [disabled]="page == pages">
            <i class="fas fa-angle-double-right"></i>
        </button>
    </a>


</div>