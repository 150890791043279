import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchService } from '../../services/search.service';

import createNumberMask from 'text-mask-addons/dist/createNumberMask'


@Component({
  selector: 'search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.css']
})
export class SearchFiltersComponent {

  @Output() close = new EventEmitter<any>();

  filters;

  selectedFilters;

  categoryFilter = { categories: [], search: new String() }

  public mask = createNumberMask({ prefix: new String(), thousandsSeparatorSymbol: "." })

  constructor(private router: Router, private activatedRoute: ActivatedRoute, searchService: SearchService) {

    searchService.getFilters().subscribe((filters: any) => {

      this.filters = filters;

      activatedRoute.queryParams.subscribe(queryParams => {

        Object.keys(queryParams).forEach(queryParam => {

          let filter = this.filters.filter(f => f.name == queryParam)[0];

          if (!filter) return

          if (Array.isArray(queryParams[queryParam])) {

            queryParams[queryParam].forEach((option: any) => filter.options.filter(o => o.name == option)[0].selected = true);

          } else {

            filter.options.filter(o => o.name == queryParams[queryParam])[0].selected = true;

          }

        });

        let selectedFilters: any[] = [];

        filters.forEach(f => {

          f.options.forEach(o => {

            if (o.selected) selectedFilters.push({ filter: f.name, option: o.name })

          });

        });

        ['txt', 'min', 'max', 'category', 'list'].forEach(key => {
          queryParams[key] && selectedFilters.push({ filter: key, option: queryParams[key] })
        });

        this.selectedFilters = selectedFilters;

      });

    });

    searchService.getCategories().subscribe(r => { 
      this.categoryFilter.categories = r["data"] 
      console.log(this.categoryFilter);
    });

  }

  updateQueryParams(event, filter, option) {

    var queryParams = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams));

    if (event.target.checked) {

      if (queryParams.hasOwnProperty(filter.name)) {

        if (Array.isArray(queryParams[filter.name])) {

          if (!queryParams[filter.name].includes(option.name)) {

            queryParams[filter.name].push(option.name);

          }

        } else {

          if (queryParams[filter.name] != option.name) {

            queryParams[filter.name] = [queryParams[filter.name], option.name];

          }

        }

      } else {

        queryParams[filter.name] = option.name;

      }

    } else {

      if (Array.isArray(queryParams[filter.name])) {

        queryParams[filter.name].splice(queryParams[filter.name].indexOf(option.name), 1);

      } else {

        queryParams[filter.name] = null;

      }

    }

    this.router.navigate(["/search"], { queryParams: queryParams })

  }

  removeQueryParam(filter: string, option: string) {

    var queryParams = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams));

    if (Array.isArray(queryParams[filter])) {

      queryParams[filter].splice(queryParams[filter].indexOf(option), 1);

    } else {

      queryParams[filter] = null;

    }

    let selectedFilter = this.filters.filter(f => f.name == filter)[0];

    if (selectedFilter) selectedFilter.options.filter(o => o.name == option)[0].selected = false;

    this.router.navigate(["/search"], { queryParams: queryParams });

  }

  submitPricer(min: HTMLInputElement, max: HTMLInputElement) {

    this.router.navigate(["/search"], { queryParams: { min: min.value ? (Number(min.value.replace(".", String())) * 1000) : null, max: max.value ? Number(max.value.replace(".", String())) * 1000 : null }, queryParamsHandling: "merge" })

  }

  mobileClose() {
    this.close.emit();
  }

}
