import { Component } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';

@Component({
   selector: 'search-list',
   templateUrl: './search-list.component.html',
   styleUrls: ['./search-list.component.css']
})
export class SearchListComponent {

   items;

   constructor(private router: Router, private searchService: SearchService) {

      searchService.getItems().subscribe((items: any) => {

         this.items = items;

      })

   }

}
