import { Component, Output, EventEmitter, OnInit } from '@angular/core';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'nav-mobile',
  templateUrl: './nav-mobile.component.html',
  styleUrls: ['./nav-mobile.component.css']
})
export class NavMobileComponent implements OnInit {

  faTimes = faTimes;
  faSignInAlt = faSignInAlt;
  faClipboard = faClipboard;
  faShoppingCart = faShoppingCart;
  faCog = faCog;
  faSignOutAlt = faSignOutAlt;

  public items: any[];

  user = {
    first_name: "Guest"
  }

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getNavbar().subscribe(r => {
      this.items = this.toTree(r['message'])
    });
  }

  toTree(list) {

    var tree = list.filter(i => i.parent === "Root").map(i => { return { ...i, children: [] } })

    tree.forEach(i => {
      i.children = list.filter(e => e.parent === i.name);
    })

    tree.sort((a, b) => (a.index > b.index) ? 1 : -1)

    console.log(tree);

    return tree

  }


  @Output() closeEvent = new EventEmitter();

  collapse(b: boolean, c: HTMLDivElement) { c.style.maxHeight = b ? c.scrollHeight + "px" : null }

  close() { this.closeEvent.emit() }


}
