<mat-progress-bar mode="indeterminate" color="#fff"></mat-progress-bar>

<div class="wrapper">

    <div class="container">

        <div class="content">

            <header>
                <app-header></app-header>
            </header>

            <main id="router-outlet">
                <router-outlet></router-outlet>
            </main>

            <footer>
                <app-footer></app-footer>
            </footer>

        </div>

    </div>

</div>