<nav>
    <ul fxLayout="row" fxLayoutAlign="space-between center">

        <li fxLayout="column" *ngFor="let item of items">

            <div *ngIf="item.isGroup; then group else notGroup"></div>

            <ng-template #group>

                <p fxLayoutAlign="center center">{{ item.name }}</p>

                <ul>

                    <li *ngFor="let child of item.children">

                        <a
                        class="child-href"
                        [routerLink]="child.href"
                        [queryParams]="child.queryParams">
                            {{child.name}}
                        </a>

                    </li>

                </ul>

            </ng-template>

            <ng-template #notGroup>
                <a fxLayoutAlign="center center"
                [routerLink]="item.href"
                [queryParams]="item.queryParams"
                >
                {{ item.name }}
                </a>
            </ng-template>

        </li>

    </ul>

</nav>