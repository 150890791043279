<div class="wrapper">

    <div class="item-breadcrumb">

        <a routerLink="/">HOME</a>

        <i class="fas fa-chevron-right"></i>

        <a routerLink="/search" [queryParams]="{category: item.category}">{{ item.category }}</a>

    </div>

    <div class="content">

        <span class="item-category">{{ item.category }}</span>

        <div class="item-social">

            <a class="item-link" routerLink="/">pcgarage.com.py</a>

            <div class="item-social-media">
                <a href="https://wa.me/+595983800173"><i class="fab fa-whatsapp"></i></a>
                <a href="https://instagram.com/pcgarage.py"><i class="fab fa-instagram"></i></a>
                <a href="https://facebook.com/PcGarage.Py"><i class="fab fa-facebook-f"></i></a>
            </div>

        </div>

        <div class="item-info">

            <div class="item-name">{{ item.name }}</div>

            <div class="item-prices" *ngIf="item.availability == 1">

                <div class="item-price item-price-regular" [class.item-price-strikethrough]="item.offer">
                    <span class="item-price-symbol">₲</span>
                    {{ item.price | currency:'PYG':'':'1.0-0'}}
                </div>

                <div class="item-price item-price-offer" *ngIf="item.offer">
                    <span class="item-price-symbol">₲</span>
                    {{ item.offer | currency:'PYG':'':'1.0-0'}}
                </div>

                <div class="item-price-warning">IVA no incluido</div>

            </div>

            <div class="item-pay-methods">

                <div class="item-pay-method" matTooltip="Efectivo" matTooltipPosition="above">
                    <i class="fas fa-money-bill-alt"></i>
                </div>

                <div class="item-pay-method" matTooltip="Transferencia Bancaria" matTooltipPosition="above">
                    <img src="/assets/img/vision-logo.png">
                </div>

                <div class="item-pay-method" matTooltip="Giros Tigo" matTooltipPosition="above">
                    <img src="/assets/img/tigo-logo.png">
                </div>

            </div>

            <div class="item-not-available" *ngIf="item.availability == 0">
                NO DISPONIBLE
            </div>

            <a class="item-buy"
                [href]="'http://wa.me/+595983800173?text=Hola, estoy interesado en el producto https://pcgarage.com.py' + router.url ">
                <i class="fab fa-whatsapp"></i>
                {{ item.availability == 1 ? 'COMPRAR' : "SOLICITAR PRESUPUESTO" }}
            </a>

            <a class="item-manufacturer" [href]="item.manufacturerLink" *ngIf="item.manufacturerLink" target="_blank" rel="noopener noreferrer">MÁS INFO <i class="fas fa-external-link-alt"></i></a>

        </div>

        <div class="item-image" *ngIf="images && images.length > 0">

            <div class="item-images">
                <img *ngFor="let image of images; let i = index" [src]="'https://erp.pcgaragepy.com' + image.link"
                    [class.item-image-previous]="index > i" [class.item-image-next]="index < i">
            </div>

            <div class="item-image-selector">

                <button (click)="index + 1 > 1 ? index = index - 1 : index=index" [disabled]="index + 1 == 1"><i
                        class="fas fa-chevron-up"></i></button>

                <span class="item-image-index">{{ index + 1 }}</span>

                <span class="item-image-selector-divider"></span>

                <span class="item-image-total">{{ images.length }}</span>

                <button (click)="index < images.length - 1 ? index = index + 1 : index=index"
                    [disabled]="index + 1 == images.length"><i class="fas fa-chevron-down"></i></button>

            </div>

        </div>

    </div>

</div>