import { Component, Input } from '@angular/core';

export interface GlideItem {
  code: string;
  name: string;
  category: string;
  brand: string;
  price: number;
  salePrice: number;
  validFrom: Date;
  validUpto: Date;
}

@Component({
  selector: 'glide-items',
  templateUrl: './glide-items.component.html',
  styleUrls: ['./glide-items.component.css']
})
export class GlideItemsComponent {

  @Input() items: GlideItem[];

}
