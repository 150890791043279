import { Component } from '@angular/core';

import { faSortNumericDown } from '@fortawesome/free-solid-svg-icons';
import { faSortNumericUp } from '@fortawesome/free-solid-svg-icons';

import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons';
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';

import { faMouse } from '@fortawesome/free-solid-svg-icons';

import { faTh } from '@fortawesome/free-solid-svg-icons';

import { faThList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'search-sort',
  templateUrl: './search-sort.component.html',
  styleUrls: ['./search-sort.component.css']
})
export class SearchSortComponent {

  faSortNumericDown = faSortNumericDown;
  faSortNumericUp = faSortNumericUp;

  faSortAlphaDown = faSortAlphaDown;
  faSortAlphaUp = faSortAlphaUp;

  faMouse = faMouse;

  faTh = faTh;
  faThList = faThList;

}
