import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getNavbar().subscribe(r => {
      this.items = this.toTree(r['message'])
    });
  }

  toTree(list) {

    var tree = list.filter(i => i.parent === "Root").map(i => { return { ...i, children: [] } })

    tree.forEach(i => {
      i.children = list.filter(e => e.parent === i.name);
    })

    tree.sort((a, b) => (a.index > b.index) ? 1 : -1)

    return tree

  }

  public items;

}
