import {  AfterViewInit, Component } from '@angular/core';
import { GlideItem } from '../components/glide/glide-items/glide-items.component';
import { HomepageService } from '../services/homepage.service';

@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements AfterViewInit {


  public categories: any[] = [{
    name: "mouse",
    category: "Mouse",
    image: "/assets/img/mouse.png",
    link: "/search",
    queryParams: { category: "Mouse" },
    'background-text': "mouse",
  },
  {
    name: "mousepad",
    category: "Mouse Pad",
    image: "/assets/img/mousepad.png",
    link: "/search",
    queryParams: { category: "Mouse Pad" },
    'background-text': "pad",
  },
  {
    name: "headset",
    category: "Headset (Auricular)",
    image: "/assets/img/headset.png",
    link: "/search",
    queryParams: { category: "Headset (Auricular)" },
    'background-text': "headset",
  },
  {
    name: "keyboard",
    category: "Teclado",
    image: "/assets/img/keyboard.png",
    link: "/search",
    queryParams: { category: "Teclado" },
    'background-text': "Teclado",
  }]

  public countdownGlideItems: GlideItem[];
  public countdownGlideLink: string;

  public saleGlideItems: GlideItem[];
  public saleGlideLink: string;

  public featuredGlideItems: any[];

  constructor(public homepageService: HomepageService) { }

  ngAfterViewInit(): void {

    this.homepageService.getCountdownCarouselItems().subscribe((r:any) => {

      this.countdownGlideItems = r['message']['items'].map(i => { return { ...i, name: i.name.split(`${i.category} `).join('') } });
      this.countdownGlideLink = r['message']['list'];

    });

    this.homepageService.getSaleCarouselItems().subscribe((r: any) => {

      this.saleGlideLink = r['message']['list'];

      this.saleGlideItems = r['message']['items'].map(i => { return { ...i, name: i.name.split(`${i.category} `).join('') } })

    })
    
    this.homepageService.getFeaturedCarouselItems().subscribe((r: any) => {

      this.featuredGlideItems = r['message'].map(i => { return { ...i, name: i.name.split(`${i.category} `).join('') } })

    })

    this.homepageService.getCategoriesGrid().subscribe((r: any) => {

      this.categories = this.categories.map((c: any) => c = { ...c, 'starting-from': r['message'].filter((o: any) => o.name == c.category)[0]['starting-from'] })

    })
    
  }

}
