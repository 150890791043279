import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import Glide from '@glidejs/glide'

@Component({
  selector: 'glide',
  templateUrl: './glide.component.html',
  styleUrls: ['./glide.component.css']
})
export class GlideComponent implements OnInit, AfterViewInit {

  @ViewChild("glide") glide: ElementRef;

  @Input() options: Object = {
    type: "carousel",
    startAt: 0,
    perView: 6,
    focusAt: "center",
    breakpoints: {
      1440: {
        perView: 5
      },
      1280: {
        perView: 4
      },
      1024: {
        perView: 3
      },
      768: {
        perView: 2
      },
      512: {
        perView: 1
      }
    }
  }

  glideNumber: number;

  ngOnInit(): void {
    this.glideNumber = Math.round(Math.random() * 1000000);
  }

  ngAfterViewInit(): void {
    this.mount();
  }

  private mount = () => {
    if (this.glide.nativeElement.getElementsByClassName("glide__slide").length == 0) {
      window.setTimeout(this.mount);
    } else {
      new Glide('.glide-'.concat(this.glideNumber.toString()), this.options).mount();
    }
  }

}
