<div class="wrapper">

    <div class="container">

        <section class="search-sort-sorter" matTooltip="Ordernar por" [matTooltipPosition]="'above'">

            <span class="search-sort-title">Ordernar por</span>

            <div class="search-sort-buttons">

                <input type="radio" id="name" name="orderby" checked>
                <label for="name" routerLink="/search" [queryParams]="{order: 'name'}" queryParamsHandling="merge"
                    matTooltip="Nombre">A</label>

                <input #sort_price type="radio" id="price" name="orderby">
                <label for="price" routerLink="/search" [queryParams]="{order: 'price'}" queryParamsHandling="merge"
                    matTooltip="Precio">$</label>

                <input type="radio" id="category" name="orderby">
                <label for="category" routerLink="/search" [queryParams]="{order: 'category'}" queryParamsHandling="merge"
                    matTooltip="Categoria">
                    <fa-icon [icon]="faMouse"></fa-icon>
                </label>

            </div>

        </section>

        <section class="search-sort-sorter" matTooltip="Orden" [matTooltipPosition]="'above'">

            <span class="search-sort-title">Orden</span>

            <div class="search-sort-buttons">

                <input type="radio" id="A1" name="orderby_sort" checked>

                <label for="A1" routerLink="/search" [queryParams]="{sort: 'asc'}" queryParamsHandling="merge"
                    [matTooltip]="((sort_price.checked) ? '1 > 9' : 'A > Z') + ' (Ascendente)'">
                    <fa-icon [icon]="(sort_price.checked) ? faSortNumericDown : faSortAlphaDown"></fa-icon>
                </label>

                <input type="radio" id="Z9" name="orderby_sort">

                <label for="Z9" routerLink="/search" [queryParams]="{sort: 'desc'}" queryParamsHandling="merge"
                    [matTooltip]="((sort_price.checked) ? '9 > 1' : 'Z > A') + ' (Descendente)'">
                    <fa-icon [icon]="(sort_price.checked) ? faSortNumericUp : faSortAlphaUp"></fa-icon>
                </label>

            </div>

        </section>

        <section class="search-sort-sorter" matTooltip="Items por página" [matTooltipPosition]="'above'">

            <span class="search-sort-title">Items por página</span>

            <div class="search-sort-buttons">

                <input type="radio" id="15" name="pagination" checked>
                <label for="15" routerLink="/search" [queryParams]="{limit: 15}" queryParamsHandling="merge">15</label>

                <input type="radio" id="30" name="pagination">
                <label for="30" routerLink="/search" [queryParams]="{limit: 30}" queryParamsHandling="merge">30</label>

                <input type="radio" id="45" name="pagination">
                <label for="45" routerLink="/search" [queryParams]="{limit: 45}" queryParamsHandling="merge">45</label>

                <input type="radio" id="60" name="pagination">
                <label for="60" routerLink="/search" [queryParams]="{limit: 60}" queryParamsHandling="merge">60</label>

            </div>

        </section>

        <section class="search-sort-sorter search-sort-view" matTooltip="Vista" [matTooltipPosition]="'above'">

            <div class="search-sort-buttons">

                <input type="radio" id="th" name="view" checked>
                <label for="th" routerLink="/search" [queryParams]="{view: 'th'}" queryParamsHandling="merge"
                    matTooltip="Galería">
                    <fa-icon [icon]="faTh"></fa-icon>
                </label>

                <input type="radio" id="faThList" name="view">
                <label for="faThList" routerLink="/search" [queryParams]="{view: 'th-list'}" queryParamsHandling="merge"
                    matTooltip="Lista">
                    <fa-icon [icon]="faThList"></fa-icon>
                </label>

            </div>

        </section>

    </div>
</div>