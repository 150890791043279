import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit {

  @Input() item;

  selected: boolean;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.selected = Object.values(this.activatedRoute.snapshot.queryParams).includes(this.item.code);
    console.log(this.item)
  }

  getNameDiff() {
    return this.item.name.split(this.item.category + " ").join('');
  }

}
