<div class="wrapper">
  <search-filters #filters [class.opened]="mobileFilters" (close)="mobileFilters = false"></search-filters>
  <div class="content">
    <div class="search-result-list">
      <search-sort></search-sort>
      <search-list></search-list>
      <search-paginator></search-paginator>
    </div>
  </div>
  <div class="mobile-toggle-filters" (click)="mobileFilters = !mobileFilters" [class.opened]="mobileFilters">
    FILTROS
    <i class="fas fa-filter"></i>
  </div>
</div>