import { Component, OnInit } from '@angular/core';
import { HomepageService } from 'src/app/services/homepage.service';

interface ApiResponse {
  message: CountdownCarousel
}

interface CountdownCarousel {
  show: boolean;
  title: string;
  subtitle: string;
  validUpto: Date;
  items: Array<CarouselItem>
  list: string;
}

interface CarouselItem {
  code: string;
  name: string;
  category: string;
  brand: string;
  image: string;
  price: Number;
  salePrice: Number;
}

@Component({
  selector: 'hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {

  constructor(private service: HomepageService) { }

  show: boolean;
  title: string;
  subtitle: string;
  validUpto: Date;
  items: Array<CarouselItem>
  list: string;
  background: string;

  now: Date = new Date(Date.now());

  ngOnInit() {
    this.service.getCountdownCarouselItems().subscribe((r: ApiResponse) => {

      Object.keys(r.message).forEach(k => {
        this[k] = r.message[k];
      })

      const validUpto = new Date(this.validUpto);

      this.validUpto = validUpto;

    });
  }

}
