import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  public faBars = faBars;
  public faUser = faUser;
  public faShoppingCart = faShoppingCart;

  public user: any = {
    name: "bienvenido!"
  }

  public cart: any = {
    qty: "0"
  }

  constructor(private router: Router) {}

  toggleNavMobile() {
    $("nav").parent().toggleClass("open");
    $("html, body").toggleClass("noscroll");
  }

  toggleSearchBar() {
    $(".mobile-search").toggleClass("open");
  }

  search(txt: HTMLInputElement) { 
    if(txt.value) {
      this.router.navigate(["/search"], { queryParams: { txt: txt.value }, queryParamsHandling: "merge"});
      txt.value = String();
    }
  }

}
