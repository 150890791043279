import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchService {

  private URL = 'https://erp.pcgaragepy.com/api/';

  private METHOD_SEARCH = 'method/erpnext.shop.search.search';
  private METHOD_FILTERS = 'method/erpnext.shop.search.filters';
  private METHOD_ITEM = 'method/erpnext.shop.search.item';

  private items = new BehaviorSubject([]);
  private filters = new BehaviorSubject([]);
  private count = new BehaviorSubject(0);
  private pricer = new BehaviorSubject({});

  constructor(private http: HttpClient) { }

  search(category?, order?, sort?, page?, limit?, txt?, min?, max?, filters?) {

    let params = {}

    if (category) params['category'] = category;
    if (order) params['order'] = order;
    if (sort) params['sort'] = sort;
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (txt) params['txt'] = txt;
    if (min) params['min'] = min;
    if (max) params['max'] = max;

    if (filters) {
      for (var attribute in filters) {
        params[attribute] = filters[attribute];
      }
    }

    this.http.get(this.URL + this.METHOD_SEARCH, { params: params }).subscribe((r: any) => {

      this.items.next(r.message['items'] || []);

      this.count.next(r.message['count'] || 0);

      this.pricer.next(r.message['pricer'] || {});

    });

  }

  updateFilters(category?) {

    let params = {};

    if (category) params['category'] = category;

    this.http.get(this.URL + this.METHOD_FILTERS, { params: params }).subscribe((r: any) => {

      this.filters.next(r.message['filters'] || []);

    });

  }

  getCategories() {
    return this.http.get("https://erp.pcgaragepy.com/api/resource/Item%20Group?fields=%5B%22name%22%5D&filters=%5B%5B%22show_in_website%22,%22=%22,%221%22%5D%5D&order_by=name&limit_page_length=9999");
  }

  getItem(code) {
    return this.http.get(this.URL + this.METHOD_ITEM, { params: { code: code } })
  }

  getItems(): Observable<any> {
    return this.items.asObservable();
  }

  getFilters(): Observable<any> {
    return this.filters.asObservable();
  }

  getCount(): Observable<any> {
    return this.count.asObservable();
  }

  getPricer(): Observable<any> {
    return this.pricer.asObservable();
  }

}