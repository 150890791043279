import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

declare var FlipDown: any;

@Component({
  selector: 'flipdown',
  template: '<div id="flipdown" class="flipdown"></div>',
  styleUrls: ['./flipdown.component.css']
})
export class FlipdownComponent implements OnChanges {

  //date e.g: '2020-11-28T15:00:00'
  @Input() date: Date;

  @Input() theme: string = "light";

  @Input() headings: Array<String> = ["DÍAS", "HORAS", "MINS", "SEGS"];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date.isFirstChange) this.setFlipdown(changes.date.currentValue);
  }

  setFlipdown(date: Date) {

    if (date) {

      const flipdownDate = new Date(date);

      new FlipDown(flipdownDate.getTime() / 1000, { theme: this.theme, headings: this.headings }).start();

    }

  }

}
