<glide>

    <ul class="glide__slides">

        <li class="glide__slide" fxLayout="column" fxLayoutAlign="center center" *ngFor="let item of items">

            <a class="item-wrapper" fxLayout="column" fxLayoutAlign="center center" [routerLink]="['/item', item.code]">

                <div class="item-image" fxLayoutAlign="center center">
                    <img [src]="'https://erp.pcgaragepy.com' + item.image">
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" class="item-info">
                    <span class="item-name">{{ item.name }}</span>
                    <span class="item-category">{{ item.category }}</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" class="item-prices">
                    <span class="item-price" [class.line-through]="item.salePrice">₲ {{ item.price | currency:'PYG':'':'1.0-0' }}</span>
                    <span class="item-sale-price" *ngIf="item.salePrice">₲
                        {{ item.salePrice | currency:'PYG':'':'1.0-0' }}</span>
                </div>

                <sale-tag [price]="item.price" [salePrice]="item.salePrice" *ngIf="item.salePrice"></sale-tag>

            </a>

        </li>

    </ul>

    <div class="glide__bullets" data-glide-el="controls[nav]">
        <button class="glide__bullet" *ngFor="let item of items; let i = index" data-glide-dir="=i"></button>
    </div>

</glide>