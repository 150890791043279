import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sale-tag',
  templateUrl: './sale-tag.component.html',
  styleUrls: ['./sale-tag.component.css']
})
export class SaleTagComponent implements OnInit {

  @Input() price: number;
  @Input() salePrice: number;

  public value: number;

  ngOnInit() {

    var value = Math.round((1 - (this.salePrice / this.price)) * 100);

    this.value = value;

  }

}
